input{
  width: 70%;
  border: 1px solid $formborders;
  height: 40px;
  line-height: 40px;
  padding: 5px;
  @media (max-width: $lg) {
    width: 100%;
  }
}
input[readonly="readonly"]{
  background-color: #efefef;
}
