.parsley-errors-list{
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(-5px - 1em);
  left: 30%;
  @include ms-respond(font-size, 0.5);
  @media (max-width: $lg) {
    left: 0;
    top: -1em;
  }
  color: $red;
  li{
    list-style: none;
  }
}
.parsley-error{
  outline: 1px solid $red;
}
