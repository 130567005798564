nav.petition-nav{
  justify-content: space-around;
  a{
    @media (max-width: $lg) {
      width: 100%;
      margin: 2vw 0;
    }
  }
}
.next,
.send{
  float: right;
}
.prev{
  float: left;
}
// .slider{
//   outline: none;
//   display: none;
//   &.slick-initialized{
//     display: block;
//   }
// }
.slider:not(.slick-initialized){
  height: 0;
  position: absolute;
  overflow: hidden;
}
#petition-form{
  .inactive{
    pointer-events: none;
    border-color: $darkgrey;
    color: $darkgrey;
    opacity: 0.2;
    background-color: $lightgrey;
  }
}
