textarea{
  width: 70%;
  border: 1px solid $formborders;
  padding: 5px;
  resize: none;
  min-height: 100px;
  @media (max-width: $lg) {
    width: 100%;
  }
}
textarea[readonly="readonly"]{
  background-color: #efefef;
}
