form{
  @include ms-respond(font-size, 1);
  fieldset{
    border: none;
    margin: 0;
    padding: 0.2vw;
    >div{
      display: flex;
      margin: 1em 0 1.5em;
      position: relative;
      @media (max-width: $lg) {
        flex-wrap: wrap;
      }
    }
  }
}
