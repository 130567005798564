.person{
  margin: 3vw 0 5vw 0;
  color: $white;
  text-align: center;
  .img{
    width: 130px;
    height: 130px;
    margin: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    @media (max-width: $lg) {
      width: 40px;
      height: 40px;
    }
  }
  span{
    display: block;
    text-align: center;
  }
  .names{
    margin-top: 2vw;
    @include ms-respond(font-size, 1.2);
    @media (max-width: $lg) {
      opacity: 0;
    }
  }
  .unit{
    margin-top: 0.5vw;
    @include ms-respond(font-size, 0.9);
    @media (max-width: $lg) {
      opacity: 0;
    }
  }
  .credits{
    margin-top: 3vw;
    @media (max-width: $lg) {
      opacity: 0;
    }
    .holiday,
    .time{
      @include ms-respond(font-size, 0.6);
    }
    >div{
      margin-top: 0.5vw;
    }
  }
}
