#subjects{
  input[type="radio"]:checked + label{
    background-color: $blue;
  }
  label{
    position: static;
    height: auto;
    line-height: normal;
    padding: 0;
    &:after,
    &:before{
      display: none;
    }
  }
  ul{
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    @media (max-width: $lg) {
      flex-wrap: wrap;
    }
    li{
      list-style: none;
      padding: 0.5vw;
      flex: 1 0 20%;

      div{
        padding-top: 100%;
        position: relative;
        @media (max-width: $lg) {
          padding-top: 0;
          height: 50px;
        }
        label{
          color: $white;
          background-color: $darkgrey;
          text-decoration: none;
          text-align: center;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 1vw;
          @media (max-width: $lg) {
            flex-wrap: nowrap;
          }
          &:hover{
            background-color: $blue;
          }
          i{
            @include ms-respond(font-size, 2);
            display: inline-block;
            width: 100%;
            @media (max-width: $lg) {
              width: 50px;
            }
          }
          span{
            display: inline-block;
            width: 100%;
            @include ms-respond(font-size, 1);
            @media (max-width: $lg) {
              text-align: left;
              padding-left: 4vw;
            }
          }
        }
      }
    }
  }
}
