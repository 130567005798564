*, *::before, *::after{
  box-sizing: border-box;
}
html{
  margin: 0;
  min-width: 320px;
  height: 100%;
  background: $lightgrey;
  word-break: break-all;
}
body{
  margin: 0;
  min-height: 100%;
  display: flex;
}
main{
  min-height: 100%;
  width: calc(100% - 300px);
  margin-left: 300px;

  padding: 5vw;
  @media (max-width: $lg) {
    width: calc(100% - 50px);
    margin-left: 50px;
  }
}
section{
  min-height: 100%;
  max-width: 1200px;
  //margin: auto;
  background-color: $white;
  padding: 0 5vw 5vw 5vw;
  box-shadow: 4px -4px 9px -2px rgba(0, 0, 0, 0.2);
  h1{
    margin-top: 0;
  }
}
.inner{
  padding: 5vw;
}
.hidden{
  display: none!important;
}
.clearfix:after {
	content: '';
	clear: both;
	display: block;
	visibility: hidden;
	height: 0;
}
.true{
  color: $green;
}
.false{
  color: $red;
}
