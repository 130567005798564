// Modular scale settings
@font-face{
  font-family: areal;
  src:url(/assets/static/fonts/areal/areal-bl-black-web.eot);
  src:url(/assets/static/fonts/areal/areal-bl-black-web.eot?#iefix)
  format('eot'),url(/assets/static/fonts/areal/areal-bl-black-web.woff)
  format('woff'),url(/assets/static/fonts/areal/areal-bl-black-web.ttf)
  format('svg');
  font-weight:400;
  font-style:normal
}
html{
  font-size: 10px;
}
body{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
.areal{
  font-family: 'areal';
  text-transform: uppercase;
}
h1{
  @include ms-respond(font-size, 2.5);
}
h2{
  @include ms-respond(font-size, 2.2);
}
h3{
  @include ms-respond(font-size, 1.9);
}
h4{
  @include ms-respond(font-size, 1.6);
}
h5{
  @include ms-respond(font-size, 1.3);
}
h6{
  @include ms-respond(font-size, 1);
}
p{
  @include ms-respond(font-size, 1);
}
b{
  @include ms-respond(font-size, 1);
  font-weight: bold;
}
a{
  @include ms-respond(font-size, 1);
  text-decoration: underline;
  color: $blue;
}
i{
  @include ms-respond(font-size, 1);
}
small{
  @include ms-respond(font-size, 0.7);
}
