.btn,
button,
input[type="submit"]{
  @include ms-respond(font-size, 1);
  background-color: $white;
  display: inline-block;
  min-width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid $blue;
  text-decoration: none;
  transition: all .5s;
  margin: 2vw;
  color: $blue;
  cursor: pointer;
  &:hover{
    background-color: $blue;
    color: $white;
    border: 1px solid $blue;
    transition: all .5s;
  }
  &.accept{
    border-color: $green;
    color: $green;
    &:hover{
      background-color: $green;
      color: $white;
    }
  }
  &.refuse{
    border-color: $red;
    color: $red;
    &:hover{
      background-color: $red;
      color: $white;
    }
  }
}
input[type="submit"]{
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
button,
input[type="submit"]{
  padding: 0;
}
