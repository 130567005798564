#tabs{
  border-bottom: $lightgrey 2px solid;
  margin: 0 -5vw;
  display: flex;
  a{
    width: 100%;
    text-decoration: none;
    display: block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-family: areal;
    text-transform: uppercase;
    color: $black;
    @include ms-respond(font-size, 0.8);
    border-right: 1px solid $lightgrey;
    border-left: 1px solid $lightgrey;
    @media (max-width: $lg) {
      @include ms-respond(font-size, 0.2);
    }
    &.active{
      background-color: $blue;
      color: $white;
    }
    &:first-of-type{
      border-left: 0;
    }
    &:last-of-type{
      border-right: 0;
    }
    &:hover{
      background-color: $blue;
      color: $white;
    }
  }
}
