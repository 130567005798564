#login{
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  section{
    width: 100%;
    max-width: 800px;
    padding: 5vw;
    min-height: 0;
    form{
      margin-bottom: 0;
      fieldset{
        >div{
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
      input[type="submit"]{
        margin-bottom: 0;
      }
    }
  }
}
.login{
  height: 100%;
}
