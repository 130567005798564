input[type="file"]{
  top: 0;
  right: 0;
  left: 30%;
  bottom: 0;
  visibility: none;
  opacity: 0;
  position: absolute;
  @media (max-width: $lg) {
    width: 100%;
    left: 0;
  }
  &:focus +label{
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
  }
  + label{
      width: 100%;
      display: flex;
      cursor: pointer;
      @media (max-width: $lg) {
        flex-wrap: wrap;
      }
      &::before,
      &::after{
        display: block;
        box-sizing: inherit;
      }
      &::before{
        content: attr(data-label);
        width: 30%;
        line-height: 40px;
        @media (max-width: $lg) {
          width: 100%;
        }
      }
      &::after{
        content: attr(data-file);
        width: 70%;
        border: 1px solid #dadada;
        height: 40px;
        line-height: 40px;
        padding: 5px;
        background-color: white;
        color: $blue;
        @media (max-width: $lg) {
          width: 100%;
        }
      }
    }
  }
