.list{
  margin: 5vw 0;
  .header,
  .row{
    display: flex;
    padding: 1vw;
    @media (max-width: $lg) {
      flex-wrap: wrap;
    }
  }
  .header{
    background-color: $darkgrey;
    color: $white;
    @media (max-width: $lg) {
      display: none;
    }
  }
  h6,
  p{
    width: 100%;
    margin: 0;
  }
  .row{
    &:nth-child(odd){
      background-color: $lightgrey;
    }
    &:last-of-type{
      border-bottom: 1px solid $lightgrey;
    }
    &:hover{
      background-color: $blue;
      color: $white;
    }
  }
}
