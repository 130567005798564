aside#main-nav{
  background-color: $aside;
  width: 300px;
  min-width: 300px;
  position: fixed;
  bottom: 0;
  top: 0;
  @media (max-width: $lg) {
    width: 50px;
    min-width: 50px;
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      list-style: none;
      border-bottom: 1px solid $aside-color;
      position: relative;
      &:first-of-type{
        border-top: 1px solid $aside-color;
      }
      &.active{
        background-color: #43474a;
      }
      &:hover{
        background-color: #43474a;
        a{
          color: $white;
        }
      }
      a{
        text-decoration: none;
        color: $aside-color;
        display: flex;
        i{
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          text-align: center;
        }
        span{
          height: 50px;
          display: block;
          line-height: 50px;
          padding-left: 20px;
          @media (max-width: $lg) {
            display: none;
          }
        }
      }
    }
  }
  .todo{
    &::after{
      content: attr(data-todo);
      text-align: center;
      width: 18px;
      height: 18px;
      font-size: 13px;
      background-color: $red;
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      left: 31px;
      color: $white;
    }
  }
}
