#steps{
  margin: 5vw 0;
  ol{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li{
      width: 100%;
      text-align: center;
      position: relative;
      &:first-of-type{
        &::before{
          display: none;
        }
      }
      &:last-of-type{
        &::after{
          display: none;
        }
      }
      &:before,
      &:after{
        border-top: 1px solid black;
        display: block;
        content: '';
        position: absolute;
        bottom: 15px;
        width: 50%;
        z-index: 1;
      }
      &:before{
        left: 0;
      }
      &:after{
        right: 0;
      }
      .name{
        @include ms-respond(font-size, 0.6);
        text-align: center;
        font-weight: 700;
        margin-bottom: 1.5vw;
        @media (max-width: $lg) {
          display: none;
        }
      }
      a{
        text-decoration: none;
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: $blue;
        border-radius: 50%;
        color: white;
        text-align: center;
        line-height: 30px;
        position: relative;
        z-index: 3;
        @include ms-respond(font-size, 0.6);
      }
    }
  }
  .done{
    background-color: $darkgrey;
  }
  .inactive{
    background-color: $lightgrey;
    color: $black;
    cursor: default;
  }
}
