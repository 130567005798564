input[type="radio"]{
  height: 0;
  width: 0;
  visibility: none;
  opacity: 0;
  position: absolute;
  &:focus +label{
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
  }
  &:checked + label{
    &::before{
      border: 1px solid $blue;
    }
    &::after{
      top: 16px;
      left: 6px;
      width: 8px;
      height: 8px;
      background-color: $blue;
    }
  }
  +label{
    position: relative;
    display: block;
    cursor: pointer;
    height: 35px;
    line-height: 35px;
    padding: 3px 35px;
    width: 100%;
    &::before,
    &::after{
      content: '';
      position: absolute;
      border-radius: 50%;
    }
    &::before{
      top: 10px;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid $blue;
      display: inline-block;
    }
    &::after{
      display: block;
    }
  }
}
