#absences{
  margin: 5vw 0;
  .row{
    display: flex;
    &:last-of-type{
      .month{
        div{
          border-bottom: 1px solid $black;
        }
      }
    }
  }
  .name{
    width: 20%;
    height: 30px;
    line-height: 30px;
    @include ms-respond(font-size, 0.8);
  }
  .month{
    width: 80%;
    display: flex;
    div{
      width: 100%;
      text-align: center;
      border: 1px solid $black;
      border-bottom: 0;
      border-right: 0;
      height: 30px;
      line-height: 30px;
      &:last-of-type{
        border-right: 1px solid $black;
      }
    }
  }
  .weekend{
    background-color: $darkgrey;
    color: white;
  }
  .special{
    background-color: $lightgrey;
  }
  .period{
    background-color: $yellow;
    color: $white;
  }
  .away{
    background-color: $red;
    &.am{
      background-color: $white;
      position: relative;
      &::after{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
        background-color: $red;
        content: '';
        display: block;
      }
    }
    &.pm{
      background-color: $white;
      position: relative;
      &::after{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $red;
        content: '';
        display: block;
      }
    }
  }
}
