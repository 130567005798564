#status{
  margin: 5vw 0;
  ol{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li{
      width: 100%;
      text-align: center;
      position: relative;
      &:first-of-type{
        &::before{
          display: none;
        }
      }
      &:last-of-type{
        &::after{
          display: none;
        }
      }
      &:before,
      &:after{
        border-top: 1px solid black;
        display: block;
        content: '';
        position: absolute;
        top: 19%;
        width: 50%;
        z-index: 1;
      }
      &:before{
        left: 0;
      }
      &:after{
        right: 0;
      }
      .name{
        @include ms-respond(font-size, 0.6);
        text-align: center;
        font-weight: 700;
        margin-bottom: 1.5vw;
      }
      >p{
        text-decoration: none;
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: $yellow;
        border-radius: 50%;
        color: white;
        text-align: center;
        line-height: 30px;
        position: relative;
        z-index: 3;
        margin-top: 0;
        @include ms-respond(font-size, 0.6);
      }
    }
  }
  .accepted{
    background-color: $green;
  }
  .refused{
    background-color: $red;
  }
  .inactive{
    background-color: $lightgrey;
    color: $black;
  }
  .card{
    border: 1px solid $black;
    margin: 1px 1vw 0;
    z-index: 1;
    padding: 1vw;
    position: relative;
    &::before{
      width: 20px;
      height: 20px;
      z-index: 2;
      content: '';
      border-top: 1px solid $black;
      border-right: 1px solid $black;
      background-color: $white;
      display: block;
      position: absolute;
      transform: translate(-50%, calc(-50% - 1vw)) rotate(-45deg);
      left: 50%;
    }
    p,
    b{
      z-index: 3;
      position: relative;
      @include ms-respond(font-size, 0.8);
    }
    p{

    }
  }
}
